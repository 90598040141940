var Member = {
  doms: {
    member: null,
    memberName: null,
    memberRole: null,
    memberDescription: null,
    memberImage: null,
    memberBtn: null,
    memberTriggers: document.querySelectorAll('.js-member-trigger'),
  },
  buildMember: function buildMember() {
    jQuery('<div class="member js-member"><div class="member__image member__image--active js-member__image" style="background-image: url();"></div><button class="member__btn js-member__btn"><div class="member__icon"><div class="member__bar"></div><div class="member__bar"></div></div></button><div class="member__middle"><div class="member__row"><div class="member__col"><div class="member__top u-underline"></div><div class="member__name js-member__name"></div><div class="member__role js-member__role"></div><p class="member__description js-member__description"></p></div></div></div></div>').appendTo('body');

    this.doms.member = document.querySelector('.js-member');
    this.doms.memberName = document.querySelector('.js-member__name');
    this.doms.memberRole = document.querySelector('.js-member__role');
    this.doms.memberDescription = document.querySelector('.js-member__description');
    this.doms.memberImage = document.querySelector('.js-member__image');
    this.doms.memberBtn = document.querySelector('.js-member__btn');

    // on cross click, hide detailed view
    this.doms.memberBtn.addEventListener('click', this.hideMember.bind(this));

    // on Esc click, hide detailed view when opened
    var self = this;
    document.addEventListener('keydown', function (e) {
      if (e.keyCode === 27 && self.doms.member.classList.contains('member--show')) {
        self.hideMember();
      }
    });
  },
  clearMemberElement: function clearMemberElement() {
    this.doms.memberName.innerHTML = '';
    this.doms.memberRole.innerHTML = '';
    this.doms.memberDescription.innerHTML = '';
    this.doms.memberImage.style.backgroundImage = '';
  },
  showMember: function showMember(e) {
    // if member container doesn't exists in doom, create one
    if(!this.doms.member) {
      this.buildMember();
    }

    // clear member markup
    this.clearMemberElement();

    // references to data from clicked team member
    var name = e.target.parentElement.querySelector('.js-member-trigger-name').innerHTML;
    var role = e.target.parentElement.querySelector('.js-member-trigger-role').innerHTML;
    var description = e.target.parentElement.querySelector('.js-member-trigger-description').innerHTML;
    var image = e.target.parentElement.querySelector('.js-member-trigger-img').getAttribute('data-bigimage');

    // add member info to member markup
    this.doms.memberName.innerHTML = name;
    this.doms.memberRole.innerHTML = role;
    this.doms.memberDescription.innerHTML = description;
    this.doms.member.classList.add('member--show');
    this.doms.member.classList.add('member--animatein');

    var self = this;

    var img = new Image();
    img.addEventListener('load', function() {
      self.doms.memberImage.style.backgroundImage = 'url(' + img.src + ')';
      self.doms.memberImage.classList.add('member__image--active');
    });
    img.src = image;
  },
  hideMember: function hideMember() {
    var self = this;
    this.doms.member.classList.remove('member--animatein');
    this.doms.member.classList.add('member--animateout');

    setTimeout(function() {
      self.doms.member.classList.remove('member--animateout');
      self.doms.member.classList.remove('member--show');
    }, 280);
  },
  init: function init() {
    // on team member thumb click, show more detail
    for (var i = 0; i < this.doms.memberTriggers.length; i++) {
      this.doms.memberTriggers[i].addEventListener('click', this.showMember.bind(this));
    }
  }
};
