var EventsTracking = {
  doms: {
    footerLinkEmail: document.querySelector('.js-footer__link-email'),
    footerLinkCallUs: document.querySelector('.js-footer__link-callus'),
  },

  // https://developers.google.com/analytics/devguides/collection/analyticsjs/events#event_fields
  triggerEventBro: function triggerEventBro(eventCategory, eventAction, eventLabel) {
    ga('send', 'event', eventCategory, eventAction, eventLabel);
  },

  init: function init() {
    var that = this;

    if (this.doms.footerLinkEmail) {
      this.doms.footerLinkEmail.addEventListener('click', function() {
        that.triggerEventBro('Footer contact link', 'click', 'email');
      });
    }

    if (this.doms.footerLinkCallUs) {
      this.doms.footerLinkCallUs.addEventListener('click', function() {
        that.triggerEventBro('Footer contact link', 'click', 'call us');
      });
    }

  }
};
