var Portfolio = {
  doms: {
    portfolio: document.querySelector('.js-portfolio'),
    current: document.querySelector('.js-portfolio__current'),
    browse: document.querySelector('.js-browse-text'),
    options: document.querySelector('.js-portfolio__options'),
    optionsLinks: document.querySelectorAll('.js-portfolio__optionlink'),
    portfolioRow: document.querySelector('.js-portfolio__row'),
  },
  openOption: function openOption() {
    this.doms.options.classList.add('portfolio__options--active');
  },
  closeOption: function closeOption() {
    this.doms.options.classList.remove('portfolio__options--active');
  },
  handleCurrentClick: function handleClick() {
    var isOpen = this.doms.options.classList.contains('portfolio__options--active');
    if(isOpen) {
      this.closeOption();
    }
    else {
      this.openOption();
    }
  },
  chooseAnOption: function chooseAnOption(e) {
    e.preventDefault();
    var choosenOption = e.target;
    var choosenOptionName = e.target.innerHTML;
    var choosenOptionSlug = e.target.dataset.slug;

    this.doms.browse.innerHTML = 'Currently Browsing <strong>' + choosenOptionName + '</strong>';
    this.doms.browse.classList.remove(
      'portfolio__current--ar',
      'portfolio__current--vr',
      'portfolio__current--apps',
      'portfolio__current--websites',
      'portfolio__current--content',
      'portfolio__current--strategy',
      'portfolio__current--campaigns',
      'portfolio__current--latest'
    );
    this.doms.browse.classList.add('portfolio__current--' + choosenOptionSlug);
    this.closeOption();
    this.ajaxUpdate(choosenOption);
  },
  generateMarkupForSingleWork: function generateMarkupForSingleWork(posts) {

    var fragment = document.createDocumentFragment();

    posts.forEach(function(post) {
      var url = post.link;
      var imgSrc = post.featured_image;
      var title = post.title.rendered;
      var client = post.client[0] ? post.client[0].name : null;
      var clientLogo = post.client[0] ? post.client[0].logo : null;

      // render only if the elements that are required for layout are rturned correctly
      if (url && imgSrc && title) {
        var elementString = '<div class="portfolio__col u-mb">';
        elementString += '<a href="' + url + '" class="work">';
        elementString += '<img class="work__image" src="' + imgSrc + '" alt="' + title + '" />';
        elementString += '<div class="work__content">';
        elementString += '<h3 class="work__title">' + title + '</h3>';
        elementString += '</div>';
        if (client && clientLogo) {
          elementString += '<img class="work__logo" src="' + clientLogo + '" alt="' + client + ' - logo" />';
        }
        elementString += '</a>';
        elementString += '</div>';

        jQuery(fragment).append(elementString);
      }

    }, this);

    this.doms.portfolioRow.innerHTML = '';
    this.doms.portfolioRow.appendChild(fragment);
  },
  howManyPostsToPull: 2,
  currentPageID: null,
  ajaxUpdate: function ajaxUpdate(choosenArchive) {
    var self = this;
    jQuery.ajax({
      url: '/api/portfolio',
      type: "get",
      data: {
        services: choosenArchive.dataset.id,
        per_page: self.howManyPostsToPull,
        exclude: self.currentPageID,
      },
      success: function(response) {
        self.generateMarkupForSingleWork(response);

        // i'm not super proud of this mixed components usage
        // but thats the easiest and most reliable way in this case
        // sorry dude :-(
        Layers.refreshBrowserSize();
        Layers.refreshLayersInfo();
      },
      error: function(xhr) {
        // when AJAX call fails, redirect to archive page
        var clickedItemURL = choosenArchive.href;
        window.location.href = clickedItemURL;
      }
    });
  },
  init: function init() {
    if (this.doms.current && this.doms.options) {
      this.doms.current.addEventListener('click', this.handleCurrentClick.bind(this));

      this.howManyPostsToPull = this.doms.portfolio.dataset.postsnumber;
      this.currentPageID = this.doms.portfolio.dataset.id;

      for (var i = 0; i < this.doms.optionsLinks.length; i++) {
        this.doms.optionsLinks[i].addEventListener('click', this.chooseAnOption.bind(this));
      }
    }
  },
};
