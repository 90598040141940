var Process = {
  doms: {
    process: document.querySelector('.js-process'),
    processTrigger: document.querySelector('.js-enginnovation__btn'),
    processClose: document.querySelector('.js-process__close'),
    processCarousel: document.querySelector('.js-process__carousel'),
    processCarouselPrev: document.querySelector('.js-process__nav--prev'),
    processCarouselNext: document.querySelector('.js-process__nav--next'),
  },
  carousel: null,
  triggerHandler: function triggerHandler() {
    this.doms.process.classList.add('process--active');

    // if carousel have been previously initialized, just renable it
    // otherwise, build it via init() method
    if (this.carousel) {
      this.carousel.init();
    }
    else {
      this.carousel = new Siema({
        selector: this.doms.processCarousel,
        duration: 350,
      });

      var carousel = this.carousel;
      this.doms.processCarouselPrev.addEventListener('click', function (e) {
        e.preventDefault();
        carousel.prev();
      });
      this.doms.processCarouselNext.addEventListener('click', function (e) {
        e.preventDefault();
        carousel.next();
      });
    }
  },
  closeHandler: function closeHandler() {
    this.doms.process.classList.add('process--fadeout');
    (function(that) {
      setTimeout(function () {
        that.doms.process.classList.remove('process--active');
        that.doms.process.classList.remove('process--fadeout');
      }, 140);
    })(this);
    this.carousel.destroy();
  },
  init: function init() {
    if (!this.doms.process) {
      return;
    }

    // process at this moment sits inside layer inside enginnovation block
    // to span full screen it needs to move out of a div with lower z-index
    // crazy, isin't it?!
    // so im moving it to bottom of a body element
    document.body.appendChild(this.doms.process);

    this.doms.processTrigger.addEventListener('click', this.triggerHandler.bind(this));
    this.doms.processClose.addEventListener('click', this.closeHandler.bind(this));
  }
};

