var Gallery = {
  doms: {
    galleryImages: document.querySelectorAll('.js-gallery__img'),
  },
  runFallbackBro: function runFallbackBro() {
    for (var index = 0; index < this.doms.galleryImages.length; index++) {
      var image = this.doms.galleryImages[index];
      var imageSrc = image.src;

      image.style.display = 'none';
      image.parentElement.style.backgroundImage = 'url(' + imageSrc + ')';
      image.parentElement.style.backgroundSize = 'cover';
      image.parentElement.style.backgroundPosition = 'center center';

    }
  },
  init: function init() {
    // Thanks IE :-) We all do love writing fallbacks!
    if (this.doms.galleryImages && 'objectFit' in document.documentElement.style === false) {
      this.runFallbackBro();
    }
  }
};
