var Cinema = {
  doms: {
    cinema: document.querySelector('.js-cinema'),
    cinemaTrigger: document.querySelectorAll('.js-cinema-trigger'),
    cinemaClose: document.querySelector('.js-cinema__close'),
    cinemaAframe: document.querySelector('.js-cinema__iframe'),
  },
  triggerHandler: function triggerHandler(e) {
    this.doms.cinemaAframe.src = 'https://player.vimeo.com/video/' + e.target.dataset.videoid + '?title=0&byline=0&portrait=0&badge=0';
    this.doms.cinema.classList.add('cinema--active');
  },
  closeHandler: function closeHandler() {
    this.doms.cinema.classList.add('cinema--fadeout');
    this.doms.cinemaAframe.src = '';
    (function (that) {
      setTimeout(function () {
        that.doms.cinema.classList.remove('cinema--active');
        that.doms.cinema.classList.remove('cinema--fadeout');
      }, 140);
    })(this);
  },
  init: function init() {
    if (!this.doms.cinema) {
      return;
    }

    // cinema at this moment sits inside layer inside hero block
    // to span full screen it needs to move out of a div with lower z-index
    // crazy, isin't it?!
    // so im moving it to bottom of a body element
    document.body.appendChild(this.doms.cinema);

    for (var i = 0; i < this.doms.cinemaTrigger.length; i++) {
      this.doms.cinemaTrigger[i].addEventListener('click', this.triggerHandler.bind(this));
    }

    this.doms.cinemaClose.addEventListener('click', this.closeHandler.bind(this));
  }
};

