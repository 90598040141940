var Bubbles = {
  doms: {
    bubbles: document.querySelectorAll('.js-tilt')
  },
  moseMoveHandler: function moseMoveHandler(e) {
    var elementWidth = e.target.getBoundingClientRect().width;
    var elementHeight = e.target.getBoundingClientRect().height;

    console.log(elementWidth);
    console.log(elementHeight);

    e.target.style.willChange = 'transform';
    e.target.style.transform = 'perspective(1000px) rotateY(30deg)';
    // console.dir(e.layerY);
  },
  mouseEnterHandler: function mouseEnterHandler(e) {
    e.target.addEventListener('mousemove', this.moseMoveHandler);
  },
  mouseLeaveHandler: function mouseLeaveHandler(e) {
    e.target.removeEventListener('mousemove', this.moseMoveHandler);
  },
  init: function init() {
    // if (this.doms.bubbles) {
    //   for (var index = 0; index < this.doms.bubbles.length; index++) {
    //     var element = this.doms.bubbles[index];

    //     console.log(element);
    //     element.addEventListener('mouseover', this.mouseEnterHandler.bind(this));
    //     element.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this));
    //   }
    // }
  }
};
