var Jobs = {
  doms: {
    jobs: document.querySelectorAll('.js-jobs__item'),
  },
  showHideJob: function showHideJob(e) {
    var clickedStuff = e.target;

    if (clickedStuff.classList.contains('js-jobs__btn')) {
      var content = this.querySelector('.js-jobs__row');

      if (content.classList.contains('jobs__row--show')) {
        content.style.maxHeight = '0px';
        content.classList.remove('jobs__row--show');
      }
      else {
        var heightOfChildren = 0;
        for (var i = 0; i < content.children.length; i++) {
          heightOfChildren += content.children[i].getBoundingClientRect().height;
        }

        content.style.maxHeight = heightOfChildren + 'px';
        content.classList.add('jobs__row--show');
      }
    }
  },
  init: function init() {
    if (this.doms.jobs) {
      for(var i = 0; i < this.doms.jobs.length; i++) {
        this.doms.jobs[i].addEventListener('click', this.showHideJob);
      }
    }
  }
};
