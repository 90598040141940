/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // On hero__scroll click, smoothly scroll down below the hero element
        Hero.init();

        // Hide header and show on scroll back up
        Header.init();

        // Paralax layers and calculate it's position
        Layers.init();

        // Navigation functionality
        Nav.init();

        // Portfolio functionality
        Portfolio.init();

        // Members popups with more info
        Member.init();

        // Job boards
        Jobs.init();

        // Intro
        Intro.init();

        // Carousel on portfolio
        Carousel.init();

        // Gallery fallback for IE
        Gallery.init();

        // Process slider
        Process.init();

        // Questions block
        Questions.init();

        // Timeline animation and popups
        Timeline.init();

        // feature detectsions add some classes to body
        FeatureDetection.init();

        // bubbles tilt
        Bubbles.init();

        // Match news heights
        News.init();

        // Open vimeo vidos in nice looking lightbox
        Cinema.init();

        // make videos inside blog postst responsive
        SingleNewsVideos.init();

        // make videos inside blog postst responsive
        EventsTracking.init();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    contact_us: {
      init: function () {
        var $form = $(".wpcf7-form");

        $("textarea", $form).autogrow({
          vertical: true,
          horizontal: false,
        });

        $(".wpcf7-form-control-wrap", $form).on(
          "focus",
          "input, textarea, select",
          function (e) {
            // This assumes the label precedes the input in the markup.
            $(e.delegateTarget)
              .siblings()
              .eq($(e.delegateTarget).index() - 1)
              .addClass("filled focused");
          }
        );
        $(".wpcf7-form-control-wrap", $form)
          .on("blur", "input, textarea, select", function onBlur(e) {
            // This assumes the label precedes the input in the markup.
            var $label = $(e.delegateTarget)
              .siblings()
              .eq($(e.delegateTarget).index() - 1);

            $label.removeClass("focused");

            if (!e.currentTarget.value) {
              $label.removeClass("filled");
            } else {
              $label.addClass("filled");
            }
          })
          .find("input, textarea, select")
          .trigger("blur");
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
