// Hide header and show on scroll back up
var Header = {
  doms: {
    header: document.querySelector('.js-header'),
  },
  didScroll: null,
  lastScrollTop: 0,
  delta: 20,
  headerHeight: null,
  checkIfDidScroll: function checkIfDidScroll() {
    this.didScroll = true;
  },
  updateHeaderHeight: function updateHeaderHeight() {
    this.headerHeight = document.querySelector('.js-header').clientHeight;
  },
  hasScrolled: function hasScrolled() {
    var currentScrollTop = window.pageYOffset;

    // manage header color on scroll
    if (currentScrollTop >= window.innerHeight - this.headerHeight) {
      this.doms.header.classList.add('header--dark');
    }
    else {
      this.doms.header.classList.remove('header--dark');
    }
  },
  init: function init() {
    if (this.doms.header) {
      this.headerHeight = document.querySelector('.js-header').clientHeight;
      var self = this;

      window.addEventListener('resize', function () {
        self.updateHeaderHeight();
      });
      window.addEventListener('scroll', throttle(this.hasScrolled, 280, this));

    }
  }
};
